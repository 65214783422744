<template >
  <div class="back" id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "app",
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";
.back {
  background-image: radial-gradient(
    circle farthest-corner at 1.5% 1.4%,
    rgba(131, 241, 255, 0.836) 0%,
    rgb(253, 187, 151) 100.2%
  );
  min-height: 100vh;
}
@font-face {
  font-family: "Lora";
  src: local("Lora"), url(./assets/fonts/Lora.ttf) format("truetype");
}
@font-face {
  font-family: "Moonglade";
  src: local("Moonglade"), url(./assets/fonts/Moonglade.ttf) format("truetype");
}
.glass {
  backdrop-filter: blur(15px) saturate(200%);
  -webkit-backdrop-filter: blur(15px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.76);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}
.inputs{
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
 
}
.texts-font{
  font-family: "Moonglade", Helvetica, Arial;
}
</style>

<template>
  <div class="main">
        <div>
      <b-col md="auto">
        <b-img :src="logo" id="logo" center fluid @click="pushTo('/')"></b-img>
      </b-col>
    <b-alert
      v-model="showErrors"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000"
      variant="danger"
    >
      {{ textError }}
    </b-alert>

    </div>
    <div>
      <b-container class="text-center">
        <b-form>
          <div class=" pt-4 pb-4 col-md-6 mx-auto input">
            <b-col class="mx-auto" md="9">
              <b-form-input class="bfi"
                type="text"
                v-model="name"
                placeholder="Name"
              ></b-form-input>
              <div class="mt-3"></div>
            </b-col>
            <b-col class="mx-auto" md="9">
              <b-form-input class="bfi"
                type="email"
                v-model="email"
                placeholder="E-mail"
              ></b-form-input>
              <div class="mt-3"></div>
            </b-col>
            <b-col class="mx-auto" md="9">
              <b-form-input class="bfi"
                type="password"
                v-model="password"
                placeholder="Password"
              ></b-form-input>
              <div class="mt-3"></div>
            </b-col>
            <b-col class="mx-auto" md="9">
              <b-input class="bfi"
                type="password"
                v-model="Repassword"
                placeholder="Re-password"
              ></b-input>
            </b-col>
            <b-col>
              <b-button id="but"
                class=" mt-3"
                type="button"
                variant="light"
                @click="signUp()"
                >Sign up</b-button
              >
              <p class="text-light mt-3">
                You Already Have Account?<b-link
                  @click="pushTo('/login')"
                  class="text-light"
                  >Login</b-link
                >
              </p>
            </b-col>
          </div>
        </b-form>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  title: "Sign up",
  data() {
    return {
      logo: require('../../assets/logo-landing/logo.png'),
      cover: require("../../assets/logo-landing/signup.jpg"),
      email: "",
      password: "",
      Repassword: "",
      name: "",
      showErrors: false,
      textError: "",
    };
  },
  computed: {
    ...mapGetters(["USERNAME", "TOKEN", "ERRORS"]),
  },
  methods: {
    pushTo(route) {
      this.$router.push(route);
    },

    signUp() {
      if (this.email && this.password && this.Repassword && this.name) {
        if (this.password == this.Repassword) {
          if (this.password.length < 6) {
            this.showErrors = true;
            this.textError = "Passwords is too short";
          } else {
            this.$store
              .dispatch("POST_SIGNUP", {
                email: this.email,
                password: this.password,
                name: this.name,
              })
              .then(() => {
                if (this.TOKEN != null) {
                  this.$router.push("/login");
                  location.reload();
                } else if (this.ERRORS != null) {
                  this.textError = this.ERRORS;
                  this.$cookie.set("Token", this.TOKEN, {
                    expires: this.expireTime,
                  });
                  this.$cookie.set("Username", this.USERNAME.email, {
                    expires: this.expireTime,
                  });
                  this.$cookie.set("Name", this.USERNAME.name, {
                    expires: this.expireTime,
                  });
                  this.$cookie.set("UsernameId", this.USERNAME.id, {
                    expires: this.expireTime,
                  });
                  this.$cookie.set("JoinDate", this.USERNAME.created_at, {
                    expires: this.expireTime,
                  });
                  if (
                    localStorage.profile_image ===
                    this.USERNAME["profile_image"].url
                  ) {
                    this.profile_image = localStorage.profile_image;
                  } else {
                    localStorage.profile_image =
                      this.USERNAME["profile_image"].url;
                  }
                  this.$router.push("/dashboard");
                  this.rememberMe();
                  // console.log(this.$cookie.get('Token'))
                } else if (this.ERRORS !== null) {
                  this.showErrors = true;
                  this.textError = this.ERRORS;
                }
              })
              .catch((e) => {
                this.showErrors = true;
                this.textError =
                  this.ERRORS[Object.keys(this.ERRORS)[0]].error["email"][0];
              });
          }
        } else {
          this.showErrors = true;
          this.textError = "Passwords did not match";
        }
      } else {
        this.showErrors = true;
        this.textError = " Please fill all inputs!";
      }
    },
    rememberMe() {
      if (this.status) {
        this.expireTime = "1w";
      }
    },
  },
  mounted() {
    if (this.$cookie.get("Token")) {
      this.pushTo("/dashboard");
    }
  },
};
</script>


<style lang="scss" scoped>
.main {
  .title {
    font-family: "Lora", Helvetica, Arial;
  }
  // #logo {
  //   width: 250px;
  //   height: 120px;
  // }

  .input {
     background-color: #00b7ff;
    border-radius: 20px;
    box-shadow:  20px 20px 40px #969696,
             -20px -20px 40px #ffffff;

  }
  .bfi {
    border-radius: 15px;
    background: #e0e0e0;
    box-shadow: inset 5px 5px 2px #cecece,
            inset -5px -5px 2px #f2f2f2;
  }
  #but{
border-radius: 15px;
width: 100px;
background:(145deg, #f0f0f0, #cacaca);
 box-shadow: 3px 3px 10px #969696;
  }
      .text-center {
    position: relative;
    // width: 650px;
    // height: 200px;
    // right: 30px;
    top: 50px;
    .radius {
      border-radius: 15px;
    }
    }
}
</style>
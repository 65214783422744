<template>
  <div>
    <b-navbar class="navbar nav-fixed"  >
      <b-navbar-brand class="mb-1">
        <img
          :src="logo"
          width="65"
          height="60"
          class="d-inline-block align-top"
          alt="Kitten"
        />
        <label class="mt-3 texts-font">Write Together</label>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-nav-form class="mr-3 mb-1" v-if="searchRoute()">
          <b-input
            size="sm"
            class="mr-sm-2 inputs"
            placeholder="Find Story..."
            v-model="searchText"
          ></b-input>
          <div>
            <b-button
              size="sm"
              class="my-2 my-sm-0"
              variant="dark"
              type="button"
              @click="pushToSearch()"
              >Search</b-button
            >
          </div>
        </b-nav-form>
        <b-nav-form class="mr-3 mb-1" v-else>
          <b-input
            disabled
            size="sm"
            class="mr-sm-2"
            placeholder="Find Story..."
            v-model="searchText"
          ></b-input>
          <div>
            <b-button
              disabled
              size="sm"
              class="my-2 my-sm-0"
              variant="dark"
              type="button"
              @click="pushToSearch()"
              >Search</b-button
            >
          </div>
        </b-nav-form>
        <b-row>
          <b-icon
            class="ml-3 mt-2 mr-4"
            font-scale="2"
            icon="chat-square-fill"
          ></b-icon>
          <b-icon class="mt-2" font-scale="2" icon="bell-fill"> </b-icon>
          <div class="ml-1 mr-1">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              dropleft
              no-caret
              
              size="sm"
            >
              <template #button-content>
                <b-avatar :src="profile_img" size="2.5rem"></b-avatar>
              </template>
              <b-dropdown-text>
                <h6>
                  Signed in as: <b>{{ username }}</b>
                </h6>
              </b-dropdown-text>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/dashboard/Profile">Profile</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="logout()"
                ><small class="text-danger h6">Logout</small>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-row>
      </b-navbar-nav>
      <side-nav />
    </b-navbar>
  </div>
</template>

<script>
import SideNav from "./SideNav";

export default {
  components: {
    SideNav,
  },
  data() {
    return {
      logo: require("../../assets/logo-landing/logo.png"),
      profile_img: localStorage.profile_image,
      username: this.$cookie.get("Username"),
      searchText: "",
    };
  },
  methods: {
    logout() {
      this.$cookie.delete("Token");
      this.$cookie.delete("Username");
      this.$cookie.delete("UsernameId");
      this.$cookie.delete("Token");
      localStorage.profile_image = null;
      this.$router.push("/");
      location.reload();
    },
    searchRoute() {
      return this.$route.name !== "Stories";
    },
    pushToSearch() {
      if (this.searchText != "") {
        this.$router.push({
          name: "Stories",
          params: {
            tex: this.searchText,
          },
        });
        this.searchText = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
}
.navbar{
     backdrop-filter: blur(20px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.66); 
   -webkit-backdrop-filter: blur(15px) saturate(200%);
}

</style>
<template>
  <b-container class="text-center">
    <div class="mt-x-story-edit d-flex justify-content-center mt-x">
      <b-form>
        <h3 class="glass font-weight-bold text-center texts-font">
          Edit Story Data
        </h3>
        <div class="glass pt-4 pb-4" style="min-width: 40vw">
          <b-col class="mx-auto mt-4" md="9">
            <b-form-input
              type="text"
              v-model="title"
              placeholder="Title"
              class="inputs"
            ></b-form-input>
            <div class="mt-3"></div>
          </b-col>
          <b-col class="mx-auto mt-4" md="9">
            <b-form-select
              class="inputs"
              v-model="genre"
              :options="options"
            ></b-form-select>
            <div class="mt-3"></div>
          </b-col>
          <b-col class="mx-auto" md="9">
            <vue-bootstrap-typeahead
              v-model="mem"
              :data="memebers"
              placeholder="Members"
              @hit="selectMembers()"
              :maxMatches="16"
              :minMatchingChars="3"
              class="inputs"
            />
            <b-row>
              <selected-user class="mx-auto inputs" :selection="selectedMems" />
            </b-row>
          </b-col>
          <b-col offset="">
            <div>
              <b-form-checkbox
                class="text-info mt-3 mr-3"
                v-model="status"
                name="checkbox-1"
                value="private"
              >
                private
              </b-form-checkbox>
              <b-button
                class="col-md-3 mt-2"
                type="button"
                variant="dark"
                @click="submit()"
                >Submit
              </b-button>
            </div>
          </b-col>
        </div>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import SelectedUser from "../../components/SelectedUser/Index.vue";

export default {
  title: "Edit Story",
  components: {
    VueBootstrapTypeahead,
    SelectedUser,
  },
  data() {
    return {
      options: [
        { value: null, text: "Genre" },
        { value: "Drama", text: "Drama" },
        { value: "Comedy", text: "Comedy" },
        { value: "Fantasy", text: "Fantasy" },
        { value: "Romance", text: "Romance" },
        { value: "Horror", text: "Horror" },
        { value: "Action", text: "Action" },
      ],
      title: "",
      search: "",
      genre: "",
      user_id: "",
      memebers: [],
      status: false,
      mem: "",
      selectedMems: [],
    };
  },
  watch: {
    mem: function (val) {
      if (val !== null) {
        if (val.length >= 3) {
          this.search = val;
          this.searchMembers();
          this.memebers = [];
        }
      }
    },
  },
  computed: {
    ...mapGetters(["ONE_STORY", "USERNAME", "SEARCH_RESULT"]),
  },
  mounted() {
    const vm = this;
    this.$store.dispatch("GET_ONE_STORY", this.$route.params.id).then(() => {
      vm.title = this.ONE_STORY.title;
      vm.genre = this.ONE_STORY.genre;
      for (let i = 0; i < this.ONE_STORY.members.length; i++) {
        vm.selectedMems.push(this.ONE_STORY.members[i].user);
      }
    });
  },
  methods: {
    selectMembers() {
      if (this.mem !== null && this.selectedMems.includes(this.mem) === false) {
        let index = this.SEARCH_RESULT.findIndex((x) => x.email === this.mem);
        if (this.SEARCH_RESULT[index].email !== this.$cookie.get("Username")) {
          this.selectedMems.push(this.SEARCH_RESULT[index]);
          this.mem = null;
        } else {
          alert("You can't add your self to writers");
          this.mem = null;
        }
      }
    },
    searchMembers() {
      const vm = this;
      this.$store
        .dispatch("SEARCH_MEMBERS", { query: this.search })
        .then(() => {
          for (let i = 0; i < this.SEARCH_RESULT.length; i++) {
            if (vm.memebers.includes(this.SEARCH_RESULT[i].email) === false) {
              vm.memebers.push(this.SEARCH_RESULT[i].email);
            }
          }
        })
        .catch();
    },
    submit() {
      const vm = this;
      if (this.title !== "" && this.genre !== "") {
        let json_members = JSON.stringify(this.selectedMems);
        console.log(json_members);
        this.$store
          .dispatch("EDIT_STORY", {
            title: this.title,
            genre: this.genre,
            members: json_members,
            storyId: vm.$route.params.id,
            is_private: true,
            status:null,
          })

          .then(() => {
            vm.$router.push("/dashboard");
          })
          .catch((e) => {
            this.showErrors = true;
            this.textError = e;
            console.log(e);
          });
      } else {
        this.showErrors = true;
        this.textError = "Please fill all inputs!";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mt-x-story-edit {
  padding-top: 6.6rem !important;
}
</style>
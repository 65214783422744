<template>
  <div class="container mx-auto mr-5">
    <div style="padding: 10px" class="row d-flex justify-content-center">
      <b-card
        header-tag="header"
        style="max-height: 9rem"
        class="col-sm-8 col-lg-7 shadow mb-2 bg-light rounded"
      >
        <b-row>
          <b-col class="text-left">
            <h5 class="font-weight-bold">{{ stories.title }}</h5>
            <p class="text-secondary">{{ stories.genre }}</p>
          </b-col>
        </b-row>
        <b-row class="d-flex flex-row-reverse">
          <b-col>
            <b-row>
              <b-col class="text-right">
                <b-btn pill variant="outline-dark" class="mb-1 "
                  >More Info -></b-btn
                ></b-col
              >
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    isMobile: false,
    stories: Object,
  },
  computed: {
    ...mapGetters(["STORIES"]),
  },
};
</script>

<style scoped>
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.js'
import story from './modules/story.js'
import search from './modules/search.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    story,
    search,
  }
})

<template>
  <b-container class="container">
  
    <div class="text-center mt-x  d-flex justify-content-center">
      <div class="glass " style="min-width: 40vw">
      <div class="container">
        <div class="picture-container" @click="chooseFiles()">
          <input
            id="fileUpload"
            @change="onFileChange"
            type="file"
            hidden
            accept="image/png, image/gif, image/jpeg"
          />
          <div class="picture">
            <b-avatar :src="image" :rules="rules" size="6.5rem"> </b-avatar>
          </div>
        </div>
        <b-icon
          icon="x"
          @click="removeImage"
          v-if="typeof image !== String && image !== null"
        />
       
      </div>
      <div>
        <b-card-body>
          <b-card-title align="center">{{ name }}</b-card-title>
          <hr />
          <b-card-text class="mb-2"
            ><h6>{{ email }}</h6></b-card-text
          >
          <hr />
          <b-card-text class="mb-2 text-muted"
            >Date of join: {{ dateOfJoin.split("T")[0] }}</b-card-text
          >
          <hr />

          <a href="#" class="card-link">Your Stories</a>
          <a
            @click="pushTo('/dashboard/EditProfile')"
            href="#"
            class="card-link text-end"
            >Edit Profile
          </a>
        </b-card-body>
      </div>
    </div>
     </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

import SelectedUser from "../../components/SelectedUser/Index.vue";

export default {
  title: "Profile",
  components: {
    VueBootstrapTypeahead,
    SelectedUser,
  },

  data() {
    return {
      email: this.$cookie.get("Username"),
      name: this.$cookie.get("Name"),
      dateOfJoin: this.$cookie.get("JoinDate"),
      rules: [
        (files) =>
          !files ||
          !files.some((file) => file.size > 1_000_000) ||
          "Avatar size should be less than 1 MB!",
      ],
      image: localStorage.profile_image,
    };
  },

  methods: {
    pushTo(route) {
      this.$router.push(route);
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
      this.sendImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      const vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.image = null;
    },
    sendImage(file) {
      if (file.size <= 1_000_000) {
        const vm = this;
        this.$store
          .dispatch("POST_AVATAR", {
            image: file,
            userId: vm.$cookie.get("UsernameId"),
          })
          .then(() => {
            location.reload();
          });
      } else {
        alert("This file is too large max upload size is less than 1Mb");
        location.reload();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/*Profile Pic Start*/
.picture-container {
  position: relative;
  cursor: pointer;
  text-align: center;
  margin-top: 3rem;
}
.picture {
  width: 106px;
  height: 106px;
  background-color: #999999;
  border: 4px solid #cccccc;
  color: #ffffff;
  border-radius: 50%;
  margin: 0px auto;
  overflow: hidden;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.picture:hover {
  border-color: #2ca8ff;
}
.content.ct-wizard-green .picture:hover {
  border-color: #05ae0e;
}
.content.ct-wizard-blue .picture:hover {
  border-color: #3472f7;
}
.content.ct-wizard-orange .picture:hover {
  border-color: #ff9500;
}
.content.ct-wizard-red .picture:hover {
  border-color: #ff3b30;
}
.picture input[type="file"] {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
}

.picture-src {
  width: 100%;
}
/*Profile Pic End*/
.mt-x {
  padding-top: 6rem !important;
}
</style>
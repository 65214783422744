<template>
  <b-container class="text-center container">
    <b-alert
      v-model="showErrors"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000"
      variant="danger"
    >
      {{ textError }}
    </b-alert>
    <div class="d-flex justify-content-center mt-x">
      <b-form class="mt-2">
        <h2 class="glass font-weight-bold pl-5 pr-5 texts-font">Edit Profile</h2>

        <div class="glass pb-4" style="min-width: 40vw">
          <b-col class="mx-auto mt-4" md="9">
            <label>Change your Name and Email</label>
            <b-form-input
              type="text"
              v-model="name"
              placeholder="Name"
            ></b-form-input>
            <div class="mt-3"></div>
          </b-col>
          <b-col class="mx-auto mt-4" md="9">
            <b-form-input
              type="email"
              v-model="email"
              placeholder="Email"
            ></b-form-input>
            <div class="mt-3"></div>
          </b-col>
          <b-col class="mx-auto mt-4" md="9">
            <label>Change your password</label>
            <b-form-input
              type="password"
              v-model="password"
              placeholder="Password"
            ></b-form-input>
            <div class="mt-3"></div>
          </b-col>
          <b-col class="mx-auto mt-4" md="9">
            <b-form-input
              type="password"
              v-model="RePassword"
              placeholder="RePassword"
            ></b-form-input>
            <div class="mt-3"></div>
          </b-col>
          <b-row class="d-flex justify-content-center">
            <b-button
              class="ml-3 mr-3"
              type="button"
              variant="dark"
              @click="submitProfile()"
              >Save changes
            </b-button>

            <b-link
              class="mt-1"
              type="link"
              variant="dark"
              @click="pushTo('/dashboard/Profile')"
              >Discard
            </b-link>
          </b-row>
        </div>
      </b-form>
    </div>
  </b-container>
</template>


<script>
import { mapGetters } from "vuex";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

import SelectedUser from "../../components/SelectedUser/Index.vue";

export default {
  title: "Edit Profile",
  components: {
    VueBootstrapTypeahead,
    SelectedUser,
  },
  computed: {
    ...mapGetters(["USERNAME", "ERRORS"]),
  },
  data() {
    return {
      name: this.$cookie.get("Name"),
      email: this.$cookie.get("Username"),
      password: "",
      RePassword: "",
      textError: "",
      showErrors: false,
      userId: this.$cookie.get("UsernameId"),
    };
  },
  methods: {
    pushTo(route) {
      this.$router.push(route);
    },
    submitProfile() {
      this.showErrors = false;
      if (this.email === this.$cookie.get("Username")) {
        this.email = "";
      }
      if (this.password !== this.RePassword) {
        this.showErrors = true;
        this.textError = "Your password didn't match";
      } else {
        this.showErrors = false;
        this.textError = "";
      }
      if (!this.showErrors) {
        const vm = this;
        this.$store
          .dispatch("EDIT_USER", {
            email: vm.email.trim(),
            password: vm.password,
            name: vm.name.trim(),
            user_id: vm.userId,
          })
          .then(() => {
            this.$cookie.set("Name", vm.USERNAME.name);
            this.$cookie.set("Username", vm.USERNAME.email);
            vm.pushTo("/dashboard/Profile");
          })
          .catch(() => {
            vm.showErrors = true;
            vm.textError = this.ERRORS;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mt-x {
  padding-top: 6rem !important;
}
</style>
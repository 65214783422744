<template>
  <b-row>
    <b-card
      no-body
      class="text-center m-2 pl-4 pr-3 pt-1"
      v-for="(select, index) in selection"
      :key="index"
    >
      <b-row>
        <b-avatar :src="avatar + select.id" size="2.0rem"></b-avatar>
        <label class="pt-1 ml-2">{{ select.name }}</label>
        <b-icon
          class="ml-2 point"
          scale="1.5"
          icon="x"
          @click="deleteSelection(index)"
        />
      </b-row>
    </b-card>
  </b-row>
</template>

<script>
import Const from "../../Consts";

export default {
  props: {
    selection: Array,
  },
  data() {
    return {
      avatar: Const.host + "api/images/",
    };
  },
  methods: {
    deleteSelection(index) {
      if (index > -1) {
        this.selection.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.point{
  cursor: pointer;
}
</style>

<template>
  <div calss="text-center  d-flex justify-content-center">
    <b-col>
      <b-card class="mt-3 glass card mb-3 shadow">
        <template #header>
          <b-row class="pl-2 pr-2" align-h="between">
            <div class="mb-0">
              <b-icon
                icon="arrow-up"
                style="cursor: pointer"
                @click="loadMore(1)"
              ></b-icon>
              <b-icon
                icon="arrow-down"
                style="cursor: pointer"
                @click="loadmore()"
              ></b-icon>
            </div>

            <strong class="">{{ ONE_STORY.title }}</strong>
            <div>
               <b-form-checkbox v-if="isOwner() " @change="sendStatus()" v-model="st" name="status" switch>
              Story Status : {{ textStatus() }}
            </b-form-checkbox>
            </div>
           
          </b-row>
        </template>
        <div ref="lines" class="div-in-card">
          <b-row class="mt-y" v-for="(line, index) in lines" :key="index">
            <div
              class="ml-1 mr-5"
              v-b-popover.hover.top="
                'created by ' + getUserNameFromId(line.user_id)
              "
              v-html="line.line"
            ></div>
          </b-row>
          <div>
            <b-button
              v-if="STORY_LINES.story_lines.next_page_url !== null"
              class="d-flex justify-content-center m-1"
              @click="loadMore(-1)"
              >Load more</b-button
            >
          </div>
        </div>

        <template #footer>
          <b-avatar-group size="35px">
            <b-avatar
              v-for="(member, index) in ONE_STORY.members"
              :key="index"
              :src="avatar + member.user_id"
              v-b-popover.hover.top="member.user['name']"
            >
            </b-avatar>
          </b-avatar-group>
        </template>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Const from "../../Consts";

export default {
  props: {
    isLoading: true,
    status:Boolean
  },
  data() {
    return {
      avatar: Const.host + "api/images/",
      page: 1,
      lines: [],
      st:this.status
    };
  },
  watch: {
    STORY_LINES(newValue, oldValue) {
      if (!this.isLoading) {
        this.scrollDown();
      }
    },
  
  },
  methods: {
    isOwner(){
        
      return this.ONE_STORY.owner_id === parseInt(this.$cookie.get("UsernameId"))
    },
    sendStatus() {
      var status = 0;
      const vm = this;
      if (vm.st === false) {
        status = 0;
      } else if (this.st === true) {
        status = 1;
      }
      this.$store
        .dispatch("EDIT_STORY", {
          storyId: vm.ONE_STORY.id,
          status: status,
        })

        .then(() => {
          console.log(status + " here " + vm.ONE_STORY.id);
          vm.$parent.getStoryInformation();
        })
        .catch((e) => {
          this.showErrors = true;
          this.textError = e;
          console.log(e);
        });
    },
    textStatus() {
      if (this.status === null) {
        return "Is Writing";
      } else if (this.status === false) {
        return "Is Writing";
      } else {
        return "Done";
      }
    },
    getUserNameFromId(id) {
      if (parseInt(id) === parseInt(this.$cookie.get("UsernameId"))) {
        return this.$cookie.get("Name");
      } else {
        var result = "";
        var member;
        for (member in this.ONE_STORY.members) {
          if (this.ONE_STORY.members[member].user_id === id) {
            result = this.ONE_STORY.members[member].user["name"];
          }
        }
        return result;
      }
    },
    scrollDown() {
      this.$refs.lines.scrollTop = this.$refs.lines.scrollHeight;
    },
    loadMore(page) {
      const vm = this;
      if (page > -1) {
        console.log(page);
        this.page = page;
        if (this.STORY_LINES.story_lines.next_page_url === null) {
          vm.$store
            .dispatch("GET_STORY_LINES", {
              storyId: this.ONE_STORY.id,
              page: vm.page,
            })
            .then(() => {
              vm.lines = vm.STORY_LINES.story_lines["data"];
              vm.scrollDown();
            });
        } else {
          vm.$store
            .dispatch("GET_STORY_LINES", {
              storyId: this.ONE_STORY.id,
              page: vm.page,
            })
            .then(() => {
              vm.scrollDown();
              vm.lines = vm.lines.concat(vm.STORY_LINES.story_lines["data"]);
            });
        }
      } else {
        this.page = this.page + 1;
        vm.$store
          .dispatch("GET_STORY_LINES", {
            storyId: this.ONE_STORY.id,
            page: this.page,
          })
          .then(() => {
            vm.lines = vm.lines.concat(vm.STORY_LINES.story_lines["data"]);
          });
      }
    },
  },
  mounted() {
    this.lines = this.STORY_LINES.story_lines["data"];
  },
  computed: {
    ...mapGetters(["ONE_STORY", "STORY_LINES"]),
  },
};
</script>

<style scoped>
.mt-y {
  margin-left: 1%;
}
.card {
  overflow: hidden;
}
.div-in-card {
  max-height: 200px;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: scroll;
  white-space: initial;
}
@media only screen and (max-width: 780px) {
  .mt-y {
    margin-left: 1%;
  }
}
</style>
<template>
  <b-container  class="text-center margin ">
    <b-form class="d-flex justify-content-center">
      <div class="glass pt-4 pb-4 col-md-10 mx-auto mr-5 mt-4">
        <b-row class="m-3">
          <b-input-group
            >
            <b-input-group-text>
              <b-icon icon="search" />
            </b-input-group-text>
            <b-input
              v-model="searchText"
              placeholder="Search..."
            @keydown.enter.prevent='go'
            @keypress="go"
            ></b-input>
            <b-input-group-append>
              <b-button size="sm" @click="go()" variant="success"
                >Go</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-row>
        <b-row class="pb-2">
          <b-col>
            <b-form-radio
              class="ml-3 m-1"
              v-model="selected"
              value="A"
              v-on:change="go()"
              >By user</b-form-radio
            ></b-col
          >
          <b-col>
            <b-form-radio
              class="m-1"
              v-model="selected"
              value="B"
              v-on:change="go()"
              >By Story Title</b-form-radio
            ></b-col
          >
          <b-col>
            <b-form-radio class="m-1" v-model="selected" value="C" disabled
              >By Story Summary</b-form-radio
            ></b-col
          >
        </b-row>
      </div>
    </b-form>
    <div v-if="SEARCH_RESULT.length > 0">
      <div v-if="selected === 'A'">
        <user-list
          v-for="(users, index) in SEARCH_RESULT"
          :key="index"
          :users="users"
        />
      </div>
      <div v-else>
        <story-list
          v-for="(stories, index) in SEARCH_RESULT"
          :key="index"
          :stories="stories"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import SelectedUser from "../../components/SelectedUser/Index.vue";
import UserList from "../../components/Explore/ListUser.vue";
import StoryList from "../../components/Explore/ListStories.vue";

export default {
  title: "Search",
  components: {
    SelectedUser,
    UserList,
    StoryList,
  },
  computed: {
    ...mapGetters(["SEARCH_RESULT"]),
  },
  data() {
    return {
      searchText: this.$route.params.tex,
      selected: "A",
      isOnTheGo: false,
    };
  },
  methods: {
    go() {
      const vm = this;
      this.$store.commit("SET_SEARCH_RESULT", []);
      if (this.searchText != "") {
        if (this.selected === "A") {
          this.$store
            .dispatch("SEARCH_MEMBERS", { query: vm.searchText })
            .then(() => {});
        } else if (this.selected === "B") {
          this.$store
            .dispatch("SEARCH_TITLE", { query: vm.searchText })
            .then(() => {});
        }
      }
    },
    keyDown(evt){
      console.log(evt)
    }
  },
  mounted() {
    this.$store.commit("SET_SEARCH_RESULT", []);
  },
};
</script>

<style>
.margin {
  padding-top: 7rem !important;
  padding-left: 10rem !important;
}
h3 {
  text-align: left;
}
.div {
  width: 150px;
  height: 25px;
}
.genere {
  width: 65px;
  height: 25px;
}
@media only screen and (max-width: 780px) {
  .margin {
    margin-top: 6rem !important;
    margin-left: 0rem !important;
  }
}
</style>
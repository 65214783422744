import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import titleMixin from './titleMixin/titleMixin'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCookie from 'vue-cookie'


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCookie)
Vue.mixin(titleMixin)
new Vue({
  router,
  store,
  render: h => h(App)
  
}).$mount('#app')

<template>
  <div>
    <landing  />
    <!-- <landing-mobile v-else /> -->
  </div>
</template>

<script>
import Landing from "../components/Landing/index.vue";
import Const from '../Consts.js'

export default {
  title:'Write together',
  components: {
    Landing,
  },
  data() {
    return {
      mobileView: false,
    };
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= Const.WIDTH_OF_MOBILE;
    },
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
    
  },
  mounted() {
    
    if (this.$cookie.get('Token')) {
      this.$router.push("/dashboard");
    }
  },
};
</script>

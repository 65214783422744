import axios from "axios"
import Const from '../../Consts.js'

export default {
    state: {
        username: null,
        token: null,
        errors: null,
    },
    getters: {
        USERNAME: state => state.username,
        TOKEN: state => state.token,
        ERRORS: state => state.errors

    },
    mutations: {
        SET_USERNAME: (state, payload) => state.username = payload,
        SET_TOKEN: (state, payload) => state.token = payload,
        SET_ERRORS: (state, payload) => state.errors = payload

    },
    actions: {
        POST_LOGIN({ commit }, { email, password }) {
            let formData = new FormData()
            formData.append('email', email)
            formData.append('password', password)
            return axios.post(Const.host + 'api/login', formData).then(result => {
                result = result.data
                commit('SET_USERNAME', result.user)
                commit('SET_TOKEN', result.access_token)
            }).catch(e =>{
                console.log(e)
                commit('SET_ERRORS', e.response['data'].message)
            }).catch(e => {
                commit('SET_ERRORS', e.response)
            })
        },
        POST_SIGNUP({ commit }, { email, password, name }) {
            let formData = new FormData()
            formData.append('name', name)
            formData.append('email', email)
            formData.append('password', password)
            return axios.post(Const.host + 'api/register', formData).then(result => {
                result = result.data
                commit('SET_USERNAME', result.user)
                commit('SET_TOKEN', result.access_token)

            }).catch(e => {
                commit('SET_ERRORS', e.response)

            }).catch(e=>{
                commit('SET_ERRORS', e.response['data'].message)
            })
        },
        POST_AVATAR({ commit }, { image, userId }) {
            let formData = new FormData()
            formData.append('image', image)
            formData.append('user_id', userId)
            return axios.post(Const.host + 'api/images/', formData).then(result => {
                result = result.data
                localStorage.profile_image = result.url
            }).catch(e => {
                commit('SET_ERRORS', e.response)
                localStorage.profile_image=result.url
            }).catch(e=>{
                commit('SET_ERRORS', e.response['data'].message)
                console.log(e)

            })
        },
        EDIT_USER({ commit }, { name, email, password, user_id }) {
            // let formData = new FormData()
            const params = new URLSearchParams()
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            if (name !== '' || name !== null) {
                params.append('name', name)
            }
            if (email !== '' || email !== null) {
                if(email.length>0){
                params.append('email', email)
                }
            }
            if (password !== '' || password !== null) {
                if (password.length >= 8) {
                    params.append('password', password)
                }
            }
            return axios.put(Const.host + 'api/users/' + user_id, params, config).then(result => {
                result = result.data
                commit('SET_USERNAME', result.user)
            }).catch(e => {
                commit('SET_ERRORS', e.response)
            })
        }
    }


}
<template>
  <div
    v-if="!isLoading"
    @click="onContainerFocus($event)"
    class="mt-x d-flex justify-content-center"
  >
    <b-col>
      <show-story ref="showStory" :isLoading="isLoading" :status="getStatus()" />
      <b-col class="glass" v-if="findStoryMember() && ONE_STORY.status===0">
        <!-- <b-col>
          <b-avatar class="mr-2" square></b-avatar>
          <a href="">Invite To Story</a>
        </b-col> -->
        <quill-editor
          ref="editor"
          class="quil-content mb-2"
          :options="editorOption"
          @change="onEditorChange($event)"
        >
          <div id="toolbar" slot="toolbar">
            <div>{{ textLength - 1 }}\{{ 255 }}</div>
          </div>
        </quill-editor>
        <b-row>
          <b-col class="mt-4">
            <b-button variant="dark-outline" @click="alertConfirm()"
              >Leave</b-button
            >
            <b-button class="ml-1" variant="dark" @click.prevent="postLine()"
              >Submit</b-button
            >
          </b-col>
          <b-col class="pt-4 mt-1" cols="4">
            <label v-if="isTyping"
              >{{ typeUser }} Is Typing too...</label
            ></b-col
          >
        </b-row>
      </b-col>
    </b-col>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Consts from "../../Consts";

import { quillEditor } from "vue-quill-editor";
import ShowStory from "./ShowStory.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    quillEditor,
    ShowStory,
  },
  computed: {
    ...mapGetters(["ONE_STORY", "STORY_LINES"]),
  },
  data() {
    return {
      editorContent: null,
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      isTypingEvent: null,
      typingToggle: false,
      isTyping: false,
      isLoading: true,
      typeUser: "",
      // isLoading: true,
      textLength: 1,
      maxLength: 255,
    };
  },
  // watch: {
  //   STORY_LINES(newValue, oldValue) {
  //     if (!this.isLoading) {
  //       this.$refs.showStory.scrollDown();
  //     }
  //   },
  // },
  mounted() {
    this.getStoryInformation();
  },
  methods: {
      getStatus(){
      if(this.ONE_STORY.status===1){
        return true
      }else{
        return false
      }
    },
    alertConfirm() {
      this.typingToggle = false;
      this.publisTyping();
      let con = confirm("Are you sure?");
      if (con === true) {
        this.$router.go(-1);
      }
    },
    findStoryMember() {
      const vm = this;
      if (Object.keys(vm.ONE_STORY.members).length > 0) {
        const result = vm.ONE_STORY.members.findIndex(({ user_id }) => {
          return parseInt(user_id) === parseInt(vm.$cookie.get("UsernameId"));
        });
        return (
          result !== -1 ||
          parseInt(vm.ONE_STORY.owner_id) ===
            parseInt(vm.$cookie.get("UsernameId"))
        );
      }
    },
    postLine() {
      const vm = this;
      if (vm.editorContent !== null) {
        this.$store
          .dispatch("POST_LINE", {
            user_id: this.$cookie.get("UsernameId"),
            story_id: vm.$route.params.id,
            line: vm.editorContent,
          })
          .then(() => {
            vm.$refs.editor.quill.deleteText(0, vm.editorContent.length);
            vm.textLength = 1;
            // location.reload()
            let lastPage = vm.STORY_LINES.story_lines.last_page_url.split("?")[1].split("=")[1];
            vm.$refs.showStory.loadMore(lastPage);
          });
      } else {
        console.log("Error");
      }
    },
    getStoryInformation() {
      const vm = this;
      this.$store.dispatch("GET_ONE_STORY", vm.$route.params.id).then(() => {
        // vm.isLoading = false;
        this.getStoryLines();
        if (this.isTypingEvent === null) {
          var JWT_TOKEN =
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6WyJ0ZXN0Il19fQ.Br0PvcJcqi9e4_s3UEf4ARsJ-VyRvgpXg048E9Cpxs8";
          const hubURL = Consts.host + "publisher/.well-known/mercure";
          let url = new URL(hubURL);
          url.searchParams.append("topic", "stories/" + vm.$route.params.id);
          vm.isTypingEvent = new EventSource(url, {
            headers: { Authorization: `Bearer ${JWT_TOKEN}` },
          });
          vm.isTypingEvent.onmessage = function (event) {
            let message = JSON.parse(event.data);
            message = message.data["item"].data;
            if (
              message.type == "isTyping" &&
              message.user_name !== vm.$cookie.get("Name")
            ) {
              vm.isTyping = message.isTyping;
              vm.typeUser = message.user_name;
            }
            if (message.type == "new_line") {
              vm.getStoryLines();
            }
          };
        }
      });
    },
    publisTyping() {
      const vm = this;
      const data = {
        type: "isTyping",
        user_name: this.$cookie.get("Name"),
        story_id: vm.$route.params.id,
        isTyping: this.typingToggle,
      };
      vm.$store
        .dispatch("POST_IS_TYPING", {
          story_id: vm.$route.params.id,
          data: JSON.stringify(data),
        })
        .then(() => {});
    },
    onEditorChange({ quill, html, text }) {
      if (this.typingToggle === false) {
        this.typingToggle = true;
        this.publisTyping();
      }

      this.textLength = text.length;
      this.editorContent = text;
      if (quill.getLength() > this.maxLength) {
        quill.deleteText(this.maxLength, quill.getLength());
        text.slice(0, this.maxLength);
      }
    },
    onContainerFocus(div) {
      this.typingToggle = false;
      this.publisTyping();
    },
    getStoryLines() {
      const vm = this;
      // vm.isLoading = true;
      vm.$store
        .dispatch("GET_STORY_LINES", { storyId: this.ONE_STORY.id, page: 1 })
        .then(() => {
          vm.isLoading = false;
        });
    },
  },
  beforeDestroy() {
    this.typingToggle = false;
    this.publisTyping();
    if (this.isTypingEvent instanceof EventSource) {
      this.isTypingEvent.close();
    }
  },
};
</script>

<style>
.mt-x {
  padding-top: 6.5rem !important;
  margin-left: 15rem !important;
  margin-right: 5rem !important;
  margin-bottom: 5rem;
}
.quil-content {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;

  height: 100px;
}
@media only screen and (max-width: 780px) {
  .mt-x {
    margin-top: 6.5rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-bottom: 1rem;
  }

  .quil-content {
    padding-top: 10px;
    padding-bottom: 20px;
    height: 180px;
  }
}
</style>
import axios from "axios"
import Const from '../../Consts.js'

export default {
    state: {
        result: [],
    },
    getters: {
        SEARCH_RESULT: state => state.result,
    },
    mutations: {
        SET_SEARCH_RESULT: (state, payload) => state.result = payload,
    },
    actions: {
        SEARCH_MEMBERS({ commit }, { query }) {
            return axios.get(Const.host + 'api/search/App.Models.User/email/' + query)
            .then(result => {
                result = result.data
                commit('SET_SEARCH_RESULT', result)
            }).catch(e => {
                commit('SET_ERRORS', e)
            })
        },
        SEARCH_TITLE({ commit }, { query }) {
            return axios.get(Const.host + 'api/search/App.Models.Story/title/' + query)
            .then(result => {
                result = result.data
                commit('SET_SEARCH_RESULT', result)
            }).catch(e => {
                commit('SET_ERRORS', e)
            })
        },
    }


}
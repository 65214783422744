<template>
  <div class="sidenav glass-nav">
    <b-link class="glass-button texts-font" @click="pushTo('/dashboard')">My Stories</b-link>
    <b-link class="glass-button texts-font" @click="pushTo('/dashboard/Stories')">Explore</b-link>
    <b-link class="glass-button texts-font" @click="pushTo('/dashboard/createStory')">Create Story</b-link>
    <b-link class="glass-button texts-font">My Friends</b-link>
  </div>
</template>

<script>
export default {
  methods: {
    pushTo(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.sidenav {
  height: 100%*10; /* Full-height: remove this if you want "auto" height */
  width: 200px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 90px; /* Stay at the top */
  left: 0;
  background-color: #f8f9fa; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
}

/* The navigation menu links */
.sidenav a {
  padding: 5px;
  text-decoration: none;
  font-size: 20px;
  color: #000000;
  display: block;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #535353;
}

/* Style page content */
.main {
  margin-left: 160px; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

.glass-nav {
  backdrop-filter: blur(15px) saturate(200%);
  padding: 10px;
  -webkit-backdrop-filter: blur(15px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 0;
  border: 1px solid rgba(209, 213, 219, 0.3);
}
/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 690px) {
  .sidenav {
    padding-top: 20px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
.glass-button {
  backdrop-filter: blur(15px) saturate(200%);
  margin: 10px 10px;
  padding: 0;
  -webkit-backdrop-filter: blur(15px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.36);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
 text-align: center;
  
}

.glass-button:hover {
  background-color: rgba(252, 244, 240, 0.945);
  font-weight: bold;
  background-color: rgba(252, 244, 240,0.8);
}
</style>


import axios from "axios"
import Const from '../../Consts.js'

export default {
    state: {
        title: null,
        genre: null,
        members: null,
        summery: null,
        owner: null,
        listOfStories: [],
        one_story: {},
        story: null,
        story_errors: null,
        story_lines: [],
    },
    getters: {
        STORY: state => state.story,
        STORIES: state => state.listOfStories,
        ONE_STORY: state => state.one_story,
        STORY_ERRORS: state => state.story_errors,
        STORY_LINES: state => state.story_lines,

    },
    mutations: {
        SET_STORY: (state, payload) => state.story = payload,
        SET_STORIES: (state, payload) => state.listOfStories = payload,
        SET_ONE_STORY: (state, payload) => state.one_story = payload,
        SET_STORY_ERRORS: (state, payload) => state.story_errors = payload,
        SET_STORY_LINES: (state, payload) => state.story_lines = payload,

    },
    actions: {
        POST_STORY({ commit }, { title, genre, user_id, members, is_private }) {
            let formData = new FormData()
            formData.append('title', title)
            formData.append('genre', genre)
            formData.append('user_id', user_id)
            formData.append('members', members)
            formData.append('is_private', is_private)
            return axios.post(Const.host + 'api/stories', formData).then(result => {
                result = result.data
                commit('SET_STORY', result.added_story)
            }).catch(e => {
                commit('STORY_ERRORS', e)
                console.log(e)

            })
        },
        GET_STORIES({ commit }) {
            return axios.get(Const.host + 'api/stories').then(result => {
                result = result.data
                commit('SET_STORIES', result.stories)
            }).catch(e => {
                commit('STORY_ERRORS', e)
            })
        },
        GET_ONE_STORY({ commit }, storyId) {
            return axios.get(Const.host + 'api/stories/' + storyId).then(result => {
                result = result.data
                commit('SET_ONE_STORY', result)
            }).catch(e => {
                commit('STORY_ERRORS', e)
            })
        },

        GET_STORY_LINES({ commit }, { storyId, page }) {
            return axios.get(Const.host + 'api/story_lines/' + storyId + '?page=' + page).then(result => {
                result = result.data
                commit('SET_STORY_LINES', result)
            }).catch(e => {
                commit('STORY_ERRORS', e)
            })
        },
        EDIT_STORY({ commit }, { title, genre, members, storyId, is_private, status }) {
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            // let formData = new FormData()
            const params = new URLSearchParams()
            if (status === null) {
                params.append('title', title)
                params.append('genre', genre)
                params.append('members', members)
                params.append('is_private', is_private)
            } else {
                params.append('status', status)
            }


            return axios.put(Const.host + 'api/stories/' + storyId, params, config).then(result => {
                result = result.data
                commit('SET_STORY', result.story)
            }).catch(e => {
                commit('STORY_ERRORS', e)
            })
        },
        POST_LINE({ commit }, { user_id, story_id, line }) {
            let formData = new FormData()
            formData.append('user_id', user_id)
            formData.append('story_id', story_id)
            formData.append('line', line)
            return axios.post(Const.host + 'api/story_lines', formData).then(result => {
                result = result.data
            }).catch(e => {
                commit('STORY_ERRORS', e)
                console.log(e)

            })
        },
        POST_IS_TYPING({ commit }, { story_id, data }) {
            let formData = new FormData()
            formData.append('data', data)
            return axios.post(Const.host + 'api/publish/StoryEvents/stories/' + story_id, formData).then(result => {
                result = result.data
            }).catch(e => {
                commit('STORY_ERRORS', e)
                console.log(e)

            })
        },
        DELETE_STORY({ commit }, { storyId }) {
            return axios.delete(Const.host + 'api/stories/' + storyId).then(result => {
                result = result.data
                // commit('SET_STORY', result.story)
                this.dispatch('GET_STORIES')
            }).catch(e => {
                commit('STORY_ERRORS', e)
            })
        },

    }


}
<template>
  <div class="container mx-auto mr-5">
    <div class="text-center mt-x-dashboard row d-flex justify-content-center">
      <div class="glass">
        <h2 class="font-weight-bold pl-5 pr-5 texts-font">Your Feed!</h2>
      </div>
    </div>
    <div style="padding: 10px" class="row d-flex justify-content-center">
      <div v-if="loadedStories.length === 0">No Stories To Load</div>

      <b-card
        v-else
        v-for="(story, index) in loadedStories"
        :key="index"
        style="max-height: 20rem"
        class="col-sm-8 col-lg-7 mb-5 glass "
      >
        <b-row>
          <b-col>
            <b-row align-h="between">
              <div class="type2 circleBase text-center">
                <label
                  class="mt-3 pt-1 font-weight-bold"
                  style="font-size: 10px"
                >
                  {{ story.genre }}
                </label>
              </div>
              <b-link
                @click="pushToRoute('Story', story.id, story)"
                href="#"
                class="font-weight-bold text-dark mt-2"
              >
                {{ story.title }}
              </b-link>
              <div>
                <b-row>
                  <b-icon
                    icon="lock"
                    font-scale="1"
                    :key="index"
                    v-if="story.is_private"
                  ></b-icon>
                </b-row>
              </div>
            </b-row>
          </b-col>
        </b-row>

        <b-row align-h="between">
          <div class="text-muted">
            Writers:
            {{ story.members.length +1}}
          </div>
          <div>
            <label class="text-muted">Created By: {{ story.owner.name }}</label>
          </div>
          <div>
            <b-row>
              <b-icon
                @click="toggleCollapse(index)"
                icon="info-circle"
                class="mb-2 pointer"
                font-scale="1"
                :key="index"
              ></b-icon>
            </b-row>
          </div>
        </b-row>
        <b-collapse :id="'c' + index" accordion="accordion" role="tabpanel">
          <b-card style="background-color: rgba(255, 255, 255, 0.35)">
            <b-row>
              <b-col>
                <b-avatar-group size="45px">
                    <b-avatar
                    :src="avatar + story.owner_id"
                    v-b-popover.hover.top="story.owner.name"
                  >
                  </b-avatar>
                  <b-avatar
                    v-for="(member, index) in story.members"
                    :key="index"
                    :src="avatar + member.user_id"
                    v-b-popover.hover.top="member.user['name']"
                  >
                  </b-avatar>
                </b-avatar-group>
              </b-col>
              <b-col>
                <div class="text-right" v-if="creator(story.owner_id)">
                  <b-link
                    @click="pushToRoute('EditStory', story.id, story)"
                    pill
                    class="btn btn-dark"
                    >Edit</b-link
                  >
                  <b-link
                    @click="deleteStory(story.id)"
                    pill
                    class="btn btn-dark-outline ml-2"
                    >Delete</b-link
                  >
                </div>
              </b-col>
            </b-row>
            <b-row align-h="between" class="text-muted pt-2 pl-3 pr-3">
               <label v-if="story.status === 0" style="font-size: 12px">
                Status: is writing...
              </label>
               <label v-if="story.status > 0" style="font-size: 12px">
                Status: Done
              </label>
              <label style="font-size: 12px">
                Create time: {{ story.created_at.split("T")[0] }}
              </label>
            </b-row>
          </b-card>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Const from "../../Consts";

export default {
  title: "My stories",
  props: {
    isMobile: false,
  },
  data() {
    return {
      avatar: Const.host + "api/images/",
      errorImg: 'https://placekitten.com/300/300"',
      modalShow: false,
      
    };
  },
  computed: {
    ...mapGetters(["STORIES"]),
    loadedStories() {
      if (this.STORIES) {
        return this.STORIES;
      } else {
        this.$store.dispatch("GET_STORIES").then().catch();
        return false;
      }
    },
  },
  created() {
    this.$store.dispatch("GET_STORIES").then().catch();
  },
  methods: {
    toggleCollapse(index) {
      this.$root.$emit("bv::toggle::collapse", "c" + index);
    },
    creator(ownerId) {
      return this.$cookie.get("UsernameId") == ownerId;
    },
    pushToRoute(routeName, prop, story) {
      if (story.is_private) {
        if (
          parseInt(this.$cookie.get("UsernameId")) ===
            parseInt(story.owner_id) ||
          this.checkMemberInsideArray(story.members)
        ) {
          this.$router.push({
            name: routeName,
            params: {
              id: prop,
            },
          });
        } else {
          alert(
            "This one is private you must call to creator for access to it!"
          );
        }
      } else {
        this.$router.push({
          name: routeName,
          params: {
            id: prop,
          },
        });
      }
    },
    checkMemberInsideArray(arr) {
      for (let i = 0; i < arr.length; i++) {
        return (
          parseInt(arr[i].user_id) === parseInt(this.$cookie.get("UsernameId"))
        );
      }
    },
    deleteStory(storyId) {
      if (confirm("Are you sure you want to Delete this story?")) {
        this.$store.dispatch("DELETE_STORY", {
          storyId: storyId,
        });
      }
    },
  },
};
</script>

<style scoped>
.mt-x-dashboard {
  padding-top: 6rem !important;
}
.pointer {
  cursor: pointer;
}
.circleBase {
  border-radius: 50%;
}
.type2 {
  width: 58px;
  height: 58px;
  background: #ccc;
  border: 0px solid #000;
}
</style>

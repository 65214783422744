<template>
  <div class="main">
    <div class="centerImages">
      <b-col md="auto">
        <b-img :src="logo" center fluid alt="Fluid-grow image"></b-img>
        <h2 class="title">Write Together</h2>
        <!-- <h1 class="text-center title font-weight-bold" style="letter-spacing: 5px;">WIRTE TOGETHER</h1>
        <i><h5 class="text-center title font-weight-bold text-muted">
          let's write a great story together
        </h5></i> -->
        <!-- <b-img :src="cover" center fluid alt="Fluid-grow image"></b-img> -->
      </b-col>
    </div>
    <div>
      <b-container class="text-center">
        <b-form>
          <div class="pt-4 pb-4 col-md-6 mx-auto input">
            <b-col class="mx-auto" md="9"> </b-col>
            <b-col offset="">
              <div>
                <p class="p text pb-3">Lets Write Together</p>
                <b-button
                  class="but"
                  type="button"
                  variant="light"
                  @click="pushTo('/signup')"
                  >Sign up</b-button
                >
              </div>
            </b-col>
            <div class="mt-3"></div>
            <b-col offset="">
              <div>
                <b-button
                  class="but"
                  type="button"
                  variant="light"
                  @click="pushTo('/login')"
                  >Login</b-button
                >
              </div>
            </b-col>
            <div class="mt-3"></div>
            <b-col offset="">
              <div>
                <b-button
                  disabled
                  class="but"
                  type="button"
                  variant="light"
                  @click="login()"
                  >Guest
                </b-button>
              </div>
            </b-col>
          </div>
        </b-form>
      </b-container>
      <!-- <b-row>
        <b-col>
          <b-button class="ml-2 radius" @click="pushTo('/signup')" variant="info">Sign up</b-button>
        </b-col>
        <b-button class="radius"  @click="pushTo('/login')" variant="info">Log in</b-button>
      </b-row> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/logo-landing/logo.png"),
      cover: require("../../assets/logo-landing/cover.jpg"),
    };
  },
  methods: {
    pushTo(route) {
      this.$router.push(route);
    },
  },
};
</script>


<style lang="scss" scoped>
.main {
  // background-color: #fff;
  .title {
    text-align: center;
    font-family: "Lora", Helvetica, Arial;
  }
  .text {
    text-align: center;
    font-weight: bold;
    color: #f0f0f0;
    font-family: "Lora", Helvetica, Arial;
  }
  .input {
    background-color: #00b7ff;
    position: center;
    border-radius: 20px;
    box-shadow: 20px 20px 40px #969696, -20px -20px 40px #ffffff;
  }
  .bfi {
    border-radius: 15px;

    background: #e0e0e0;
    box-shadow: inset 5px 5px 2px #cecece, inset -5px -5px 2px #f2f2f2;
  }
  .but {
    border-radius: 15px;
    width: 250px;
    background: (145deg, #f0f0f0, #cacaca);
    box-shadow: 3px 3px 10px #969696;
  }
  .p {
    font-size: 15px;
    // margin-top: 20px;
  }
  .text-center {
    position: relative;
    // width: 650px;
    // height: 200px;
    //right: 0px;
    top: 50px;
    .radius {
      border-radius: 15px;
    }
  }
}
</style>
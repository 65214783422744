import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import SignUp from '../views/signup/signup.vue'
import Login from '../views/login/login.vue'
import Dashboard from '../views/Dashboard/Index.vue'
import CreateStory from '../views/CreateStory/CreateStory.vue'
import Stories from '../views/Stories/Stories.vue'
import EditStory from "../views/EditStory/EditStory.vue"
import Profile from "../views/Profile/Profile.vue"
import EditProfile from "../views/Profile/EditProfile.vue"
import Story from "../views/Story/Story.vue"
import PageNotFound from "../components/PageNotFound/index.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "landing",
    component: LandingPage,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard ,
    children:[
      {
        path: "/dashboard/createStory",
        name: "CreateStory",
        component: CreateStory
      },
      {
        path: "/dashboard/Story/:id",
        name: "Story",
        component: Story,
        props:true
      },
      {
        path: "/dashboard/Stories/:tex?",
        name: "Stories",
        component: Stories,
        props:true
      },
      {
        path: "/dashboard/editStory/:id",
        name: "EditStory",
        component: EditStory,
        props:true
      },
      {
        path: "/dashboard/Profile",
        name: "Profile",
        component: Profile
      },
      {
        path: "/dashboard/EditProfile",
        name: "EditProfile",
        component: EditProfile
      },
    ]
 
  },



  { path: "*", component: PageNotFound }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

<template>
  <div v-if='checkSameUser(users.id)' class="container mx-auto mr-5">
    <div class="text-center"></div>
    <div style="padding: 10px" class="row d-flex justify-content-center">
      <b-card
        header-tag="header"
        style="max-height: 8rem"
        class="col-sm-8 col-lg-7 shadow mb-2 bg-light rounded"
      >
        <b-row>
          <b-col>
            <b-row>
              <b-avatar
               :src="avatar+users.id" size="3.5rem"></b-avatar>
              <h5 class="font-weight-bold m-3">{{ users.name }}</h5>
            </b-row>

            <b-row class="mt-1">
              <h6 class="cut-text ml-2"><b>Email:</b> {{ users.email }}</h6>

              <b-col class="text-right">
                <small><b-link>Add to firends</b-link></small>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import Const from "../../Consts";
export default {
  props: {
    users: Object,
  },
  data() {
    return {
      avatar: Const.host + "api/images/",
    };
  },
  methods:{
    checkSameUser(userId){
        return userId != this.$cookie.get("UsernameId")
    }
  },
  computed: {},
};
</script>

<style scoped>
@media only screen and (max-width: 480px) {
  .cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 140px;
    height: 1.2em;
    white-space: nowrap;
  }
}
</style>

<template>
  <b-navbar class="nav-fixed" type="light" variant="light">
    <b-icon
      v-b-toggle.sidebar-1
      class="mr-2 ml-2 po"
      font-scale="2"
      icon="justify"
    ></b-icon>
    <b-navbar-brand class="ml-2">
      <img
        :src="logo"
        width="65"
        height="60"
        class="d-inline-block mb-2"
        alt="logo"
      />
      <label class="mt-2">Write Together</label>
    </b-navbar-brand>
    <!-- side bar section -->

    <b-sidebar id="sidebar-1" title="Write Together" backdrop shadow>
      <div class="p-3 d-flex justify-content-center">
        <b-avatar badge-variant="dark" :src="profile_img" size="6.5rem">
          <template #badge><b-icon icon="pencil-fill"></b-icon></template>
        </b-avatar>
      </div>

      <div class="px-3 py-2">
        <div class="d-flex justify-content-center mb-2">
          <b-icon
            class="m-1 mt-1 mr-4"
            font-scale="2"
            icon="chat-square-fill"
          ></b-icon>
          <b-icon class="m-1" font-scale="2" icon="bell-fill"></b-icon>
        </div>
        <div v-if="searchRoute()" class="d-flex justify-content-center">
          <b-nav-form>
            <b-form-input
              size="sm"
              class="mr-sm-2 ml-1"
              placeholder="Find Story..."
              v-model="searchText"
            ></b-form-input>
          </b-nav-form>
          <b-button
            @click="pushToSearch()"
            size="sm"
            class="my-2 my-sm-0"
            variant="dark"
            type="button"
            >Search</b-button
          >
        </div>

        <nav class="mb-3 ml-1">
          <b-nav vertical>
            <b-link class="links" @click="pushTo('/dashboard')"
              >My Stories</b-link
            >
            <b-link class="links" @click="pushTo('/dashboard/Stories')"
              >Explore Stories</b-link
            >
            <b-link class="links" @click="pushTo('/dashboard/createStory')"
              >Create Story</b-link
            >
            <b-link class="links">My Friends</b-link>
          </b-nav>
        </nav>
      </div>

      <template #footer>
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <strong class="mr-1">
            <h6 class="mt-2">
              Signed in as: <b>{{ username }}</b>
            </h6>
          </strong>
          <b-button size="sm" variant="danger" @click="logout()"
            >Logout</b-button
          >
        </div>
      </template>
    </b-sidebar>
    <!-- side bar section -->
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/logo-landing/logo.png"),
      profile_img: localStorage.profile_image,
      username: this.$cookie.get("Username"),
      searchText: "",
    };
  },
  methods: {
    pushTo(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    logout() {
      this.$cookie.delete("Token");
      this.$cookie.delete("Username");
      this.$cookie.delete("UsernameId");
      this.$cookie.delete("Token");
      localStorage.profile_image = null;
      this.$router.push("/");
      location.reload();
    },
    searchRoute() {
      return this.$route.name !== "Stories";
    },
    pushToSearch() {
      if (this.searchText != "") {
        this.$router.push({
          name: "Stories",
          params: {
            tex: this.searchText,
          },
        });
        this.searchText = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.po {
  cursor: pointer;
}
.links {
  padding: 6px 8px 25px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #000000;
  display: block;
}
.nav-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
}
</style>

<template>
  <div class="main">
    <p v-if="getTokenFromCookie() === null">
      You need to login to see this
      <b-link @click="goBackToLogin()">click here to go back in login</b-link>
    </p>
    <div v-else>
      <custom-navbar v-if="!mobileView" />
      <custom-nav-mobile v-else />
      <story-list :isMobile="mobileView" v-if="isMainRoute()" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomNavbar from "../../components/NavBar/Index.vue";
import CustomNavMobile from "../../components/NavBar/IndexMobile";
import Const from "../../Consts.js";
import StoryList from "../storyList/index.vue";
import axios from "axios";

export default {
  title: "Dashboard",
  components: {
    CustomNavbar,
    CustomNavMobile,
    StoryList,
  },
  data() {
    return { mobileView: false };
  },
  computed: {
    ...mapGetters(["USERNAME", "TOKEN"]),
  },
  methods: {
    goBackToLogin() {
      if (this.$cookie.get("Token")) {
        this.deleteCookie();
      } else {
        this.$router.push("/");
      }
    },
    getTokenFromCookie() {
      if (String(this.$cookie.get("Token")) === "null")  {
        return null;
      } else if (this.$cookie.get("Token") !== null){
        return this.$cookie.get("Token");
      } else {
        return null;
      }
    },
    deleteCookie() {
      if (this.$cookie.get("Token")) {
        this.$cookie.delete("Token");
        this.$router.push("/");
      }
    },
    handleView() {
      this.mobileView = window.innerWidth <= Const.WIDTH_OF_MOBILE;
    },
    isMainRoute() {
      return this.$route.name === "dashboard";
    },
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.$cookie.get("Token");
    axios.defaults.headers.common["Accept"] = "application/json";
  },
};
</script>

<style>
</style>